import * as echarts from "echarts";

// 弹框样式
const tooltip = () => {
  return {
    //提示框组件
    backgroundColor: "#154197",
    trigger: "axis",
    textStyle: {
      color: "#fff",
      fontStyle: "normal",
      fontFamily: "微软雅黑",
      fontSize: 12,
    },
  };
};

const yAxisData = (data: any) => {
  let OldData = {
    type: "value",
    name: "kwh",
    color1: "#ebf8ac",
    color2: "#ebf8ac",
  };
  let newData = { ...OldData, ...data };

  return {
    type: newData.type,
    name: newData.name,
    nameTextStyle: {
      color: newData.color1,
    },
    splitLine: {
      show: false,
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      show: false,
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: newData.color2,
      },
    },
  };
};

const chartStyle = (data: any) => {
  let OldData = {
    name: "测试",
    data: [999],
    type: "bar",
    color1: "pink",
    color2: "skyblue",
    rgba: "rgba(255,78,78,0.44)",
    yAxisIndex: 0,
    stack: null, // 柱状叠加
  };

  let newData = { ...OldData, ...data };

  return {
    name: newData.name,
    type: newData.type,
    yAxisIndex: newData.yAxisIndex, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
    smooth: true, //平滑曲线显示
    showAllSymbol: true, //显示所有图形。
    symbol: "circle", //标记的图形为实心圆
    stack: newData.stack,
    areaStyle: {
      // color: "rgba(5,140,255, 0.2)",
      color: newData.rgba,
    },
    lineStyle: {
      color: newData.color1,
    },
    itemStyle: {
      normal: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: newData.color1,
          },
          {
            offset: 1,
            color: newData.color2,
          },
        ]),
      },
    },
    data: newData.data,
  };
};

export { tooltip, chartStyle, yAxisData };
