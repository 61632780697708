import * as echarts from "echarts";
const barLine = () => {
  // console.log("echarts", echarts);
  return {
    title: {
      text: "",
      textStyle: {
        align: "center",
        color: "#fff",
        fontSize: 20,
      },
      top: "3%",
      left: "10%",
    },
    // backgroundColor: "#0f375f",
    grid: {
      top: "25%",
      bottom: "10%", //也可设置left和right设置距离来控制图表的大小
      left: "10%",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
        label: {
          show: true,
        },
      },
    },
    legend: {
      data: [],
      top: "15%",
      textStyle: {
        color: "#ffffff",
      },
    },
    xAxis: {
      data: [],
      axisLine: {
        show: true, //隐藏X轴轴线
        lineStyle: {
          color: "#01FCE3",
        },
      },
      axisTick: {
        show: true, //隐藏X轴刻度
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: "#ebf8ac", //X轴文字颜色
        },
      },
    },
    yAxis: [
      {
        type: "value",
        name: "",
        nameTextStyle: {
          color: "#ebf8ac",
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: true,
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          show: true,
          textStyle: {
            color: "#ebf8ac",
          },
        },
      },
      {
        type: "value",
        name: "",
        nameTextStyle: {
          color: "#ebf8ac",
        },
        position: "right",
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        axisLabel: {
          show: true,
          // formatter: "{value} %", //右侧Y轴文字显示
          textStyle: {
            color: "#ebf8ac",
          },
        },
      },
    ],
    series: [
      {
        name: "销售水量",
        type: "line",
        yAxisIndex: 1, //使用的 y 轴的 index，在单个图表实例中存在多个 y轴的时候有用
        smooth: true, //平滑曲线显示
        showAllSymbol: true, //显示所有图形。
        symbol: "circle", //标记的图形为实心圆
        // symbolSize: 10, //标记的大小
        itemStyle: {
          //折线拐点标志的样式
          color: "#058cff",
        },
        lineStyle: {
          color: "#058cff",
        },
        areaStyle: {
          color: "rgba(5,140,255, 0.2)",
        },
        data: [],
      },
      {
        name: "主营业务",
        type: "bar",
        barWidth: 15,
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#00FFE3",
              },
              {
                offset: 1,
                color: "#4693EC",
              },
            ]),
          },
        },
        data: [],
      },
      {
        name: "主营业务",
        type: "bar",
        barWidth: 15,
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#00FFE3",
              },
              {
                offset: 1,
                color: "#4693EC",
              },
            ]),
          },
        },
        data: [],
      },
    ],
  };
};
export { barLine };
